import React,{useEffect,useState} from 'react'
import './popup.css'
import logo1 from '../img/101.png';
import axios from 'axios';

function Popup({timedPopup, settimedPopup}) {

  const close=()=>{
    settimedPopup(false)

  }
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name:"",
    email:"",
    phone:"",
    message:"",
    type:"Buy"
  });
  const handleChange = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async(e)=>{
    e.preventDefault();
    console.log(data);
    const form = document.forms['formName']
    try {
      const url = `https://sheet.best/api/sheets/18e19186-a38e-4c40-97a1-4ba46f0e2e0d`
      // const corsUrl=`https://cors-anywhere.herokuapp.com${url}`
      const res = await axios.post(url,data)
      // setData(res.data.data.docs)
      setLoading(true)
      setTimeout(()=>{
        settimedPopup(false)
        setLoading(false)
       },5000)
      
    } catch (error) {
      // setLoading(false)
      alert(error)
    }
  }
  return(timedPopup)? (
    <div class="popup">
    {loading?<>
      <div class="wrapper-1">
    <div class="wrapper-2">
      <h1>Thank you !</h1>
      <p>Thanks for filling out the details  </p>
      <p>We will get in touch with you soon  </p>
      
    </div>
</div>
    
    
    </>:<div class="contentBox">
      {/* {props.children} */}
        <div class="close1" onClick={close} ><img width="40" height="40" src="https://img.icons8.com/clouds/100/close-window.png" alt="close-window"/></div>
       <div class="imgBx">
        <img src={logo1}/>
       </div>
       <div class="content">
       <div class="login-box">
  <h2>Meet Babulal & Sons</h2>
  <form onSubmit={handleSubmit}>
    <div class="user-box">
      <input type="text" name="name" value={data?.name} onChange={handleChange} required=""/>
      <label>Name</label>
    </div>
    <div class="user-box">
      <input type="text" name="phone" value={data?.phone} onChange={handleChange} required=""/>
      <label>Phone</label>
    </div>
    <div class="user-box">
      <input type="email" name="email" value={data?.email} onChange={handleChange} required=""/>
      <label>Email</label>
    </div>
    <div class="user-box">
      <select className='sel' name="type" value={data.type} onChange={handleChange}>
        <option>Buy</option>
        <option>Sell</option>
        <option>Rent</option>
        <option>Invest</option>

      </select>
      <label>What do you want ?</label>
    </div>
    <div class="user-box" style={{marginTop:"20px"}}>
      <textarea className='textt' name="message" value={data.message} onChange={handleChange}></textarea>
      <label>Message</label>
    </div>
    <button type='submit'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      Submit
    </button>
  </form>
</div>
       </div>
    </div>}
</div>

  ):"";
}

export default Popup