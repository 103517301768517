import logo from './logo.svg';
import './App.css';
import {React, useEffect,useState} from 'react'
import Nav from './Components/Nav'
import HeroPage from './Components/HeroPage';
import Main from './Components/Main';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Popup from './Components/Popup';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyA7pdOH3LOR4sdMsZutipLGcj_P29CPeB4",
    authDomain: "dosi-f5c6e.firebaseapp.com",
    projectId: "dosi-f5c6e",
    storageBucket: "dosi-f5c6e.appspot.com",
    messagingSenderId: "314530915603",
    appId: "1:314530915603:web:6ddc2f2f4023a353fee6e6",
    measurementId: "G-9K8PB5MZKT"
  };
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }, []);
  const [timedPopup, settimedPopup] = useState(false);
  useEffect(() => {
   setTimeout(()=>{
    settimedPopup(true)
   },5000)
  }, []);

  return (
 <>
 <Popup timedPopup={timedPopup} settimedPopup={settimedPopup}/>
 <Nav settimedPopup={settimedPopup}/>
 <HeroPage/>
 <Main settimedPopup={settimedPopup} />
 </>
  );
}

export default App;
