import React from 'react'
import "./main.css"
import YouTube from 'react-youtube';
import Pic1 from '../img/portfolio_01.jpg'
import Pic2 from '../img/portfolio_02.jpg'
import Pic3 from '../img/portfolio_03.jpg'
import Pic4 from '../img/portfolio_04.jpg'
import Pic5 from '../img/portfolio_05.jpg'
import Pic6 from '../img/portfolio_06.jpg'
import Pic7 from '../img/portfolio_07.jpg'
import Pic8 from '../img/portfolio_08.jpg'
import whoWeAre from '../img/2086778.jpg'
import map from '../img/Map.png'
import ReactPlayer from 'react-player';
function Main({settimedPopup}) {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
    return (
    <>
      
    <div id="what-we-do">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    
                    <div class="left-text">
                        <h4>WHO WE ARE</h4>
                        <p>Babulal Dosi & Sons, one of the oldest firms that deals in Property investments, which holds generational expertise in any kind of real estate handling in Indore! 
The city for the future. The city that drives the economy in central India. The city which is growing in all possible directions. We have seen the city BUILD, CONNECT & now we see it grow exponentially
</p>
                        <ul>
                            {/* <li>
                                <div class="white-button">
                                    <a href="#" class="scroll-link" data-id="portfolio">Discover More</a>
                                </div>
                            </li> */}
                            <li>
                                <div class="primary-button">
                                    <a onClick={()=>settimedPopup(true)}>Enquiry Now</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right-image">
                        <img src={whoWeAre} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div id="about" class="page-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-heading">
                        <h4>Our Credo</h4>
                        <div class="line-dec"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="service-item first-service">
                        <div class="icon"></div>
                        <h4>Smart property investments</h4>
                        <p>Back and forth of a location, history of the area, knowledge of all government rules and prediction of future guidelines is what we have in our 60 years of knowledge bank! Making an investment with us is like hitting the bullseye</p>
                    </div>
                </div>
                 <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="service-item second-service">
                        <div class="icon"></div>
                        <h4>Best Legal Work</h4>
                        <p>Strong legal team & smooth agreements makes us the best in town. Property and dealing with real estate is one of the most complicated journeys. Getting your slot in the registry office is a task for others but for us it's everyday</p>
                    </div>
                </div>
                 <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="service-item third-service">
                        <div class="icon"></div>
                        <h4>Smooth Documentation</h4>
                        <p>Paperwork in real estate is complex and a headache. We ensure with end to end practices and awareness of both sides in transactions that makes our client happy and never tensed with any documentation</p>
                    </div>
                </div>
                 <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="service-item fourth-service">
                        <div class="icon"></div>
                        <h4>Safe Financial Management</h4>
                        <p> Our trust with money handling and our CA team can solve any financial issue in a deal. White or black we know the hack!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="portfolio" class="page-section">
    <div class="row">
                <div class="col-md-12">
                    <div class="section-heading">
                        <h4>what we can serve</h4>
                        <div class="line-dec"></div>
                    </div>
                </div>
            </div>
<div style={{marginTop:"30px"}} class="tabs">
  
  <input type="radio" id="tab1" name="tab-control"  checked/>
  <input type="radio" id="tab2" name="tab-control"/>
  <input type="radio" id="tab3" name="tab-control" />  
  <input type="radio" id="tab4" name="tab-control"/>
  <ul>
    <li title="Features"><label for="tab1" role="button"><svg viewBox="0 0 24 24"><path d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z"/>
</svg><br/><span>Premium Plots</span></label></li>
    <li title="Delivery Contents"><label for="tab2" role="button"><svg viewBox="0 0 24 24"><path d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
</svg><br/><span>Agricultural lands </span></label></li>
    <li title="Shipping"><label for="tab3" role="button"><svg viewBox="0 0 24 24">
    <path d="M3,4A2,2 0 0,0 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8H17V4M10,6L14,10L10,14V11H4V9H10M17,9.5H19.5L21.47,12H17M6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z" />
</svg><br/><span>Residential properties</span></label></li>    <li title="Returns"><label for="tab4" role="button"><svg viewBox="0 0 24 24">
    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
</svg><br/><span>Showrooms/ Shops</span></label></li>
  </ul>
  
  <div class="slider"><div class="indicator"></div></div>
  <div class="content">
    <section>
      <h2>Premium Plots</h2>Location is one of the most important factors that decides what a property is all about. We can get you plots at locations which promises extraordinary returns, is suitable for rental income & that is located in extremely growing areas. We have plots that can be used for :
<br/>1. <b>Property for commercial purposes like malls etc</b>
<br/>2. <b>Hospital or medical use</b>
<br/>3. <b>Lands available for hotels</b> 
<br/>4. <b>Lands suitable for apartment construction </b>
<br/>5. <b>Marriage gardens </b>
<br/>6. <b>Hostels</b>
</section>
        <section>
        <h2>Agricultural Land</h2>Across the boundary of indore we can serve you with lands that can have possibilities of. - highest returns :
<br/>1. <b>suitable for colonising</b>
<br/>2. <b>Farmhouse</b>
<br/>3. <b>Warehouse</b> 
<br/>4. <b>Industrial use</b>

</section>
        <section>
          <h2>Residential properties</h2>
          Be it lands or apartments we have options that are best for your dream home 
Whatever the budget be, whatever location you want. Babulal Dosi and Sons serves you with the best possible options
</section>
    <section>
          <h2>Showrooms / Shops</h2>
          Our collection of shops and showroom spaces are unbeatable whether you want to buy, sell or rent them. We can do it easily and at the best possible rates.</section>
  </div>
</div>

    </div>
   
    <div id="blog" class="page-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-heading">
                        <h4>Our Presence in G20 Submit</h4>
                        <div class="line-dec"></div>
                    </div>
                </div>
            </div>
            <div class="row" style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
            {/* <YouTube videoId="WYQwSQXXZz0" opts={opts}  /> */}
            <ReactPlayer style={{marginLeft:"10px"}} url="https://www.youtube.com/watch?v=WYQwSQXXZz0"></ReactPlayer>
            </div>
            <div class="row">
            <div class="col-md-12">
                    <div class="pop">
                      <span>✖</span>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis neque lacinia, porta nulla in, pellentesque ante. Vivamus in luctus mauris, non pharetra nibh. Morbi porttitor nisl sit amet velit pellentesque consequat. Etiam blandit libero turpis, id vehicula leo posuere a. In vel massa sollicitudin, tincidunt massa et, tincidunt ex.<br></br>Duis volutpat condimentum mollis. Sed eleifend libero ut viverra mattis. Suspendisse consectetur diam dolor, ut efficitur sem finibus vel. Vivamus tristique lacus sed dapibus varius. Fusce pharetra, quam quis congue pretium, ante dui imperdiet ipsum, eget lobortis leo leo sed diam.</p>
                    </div>
                    <div class="pop2">
                      <span>✖</span>
                      <p>Praesent purus leo, aliquet et efficitur id, pulvinar scelerisque enim. Maecenas a arcu sagittis, ornare ante ut, suscipit lectus. Donec dolor ipsum, laoreet nec metus non, tempus elementum massa. Donec non elit rhoncus, vestibulum enim sed, rutrum arcu. Vestibulum et purus ac diam condimentum volutpat sed ac est. Phasellus interdum tortor sem. Quisque sit amet condimentum sem. Phasellus luctus, felis sit amet pulvinar luctus, lectus dui mattis tellus, et placerat nunc ante at lacus.<br></br>Vivamus vestibulum, nisi sed placerat accumsan, felis felis venenatis tortor, vel condimentum arcu neque vel mauris. Donec a magna gravida, egestas libero non, molestie massa. Integer ut dolor eget magna congue gravida ut at arcu. Vivamus maximus neque quis luctus tempus. Vestibulum consequat a justo id feugiat. </p>
                    </div>
                    <div class="pop3">
                      <span>✖</span>
                      <p>Nullam rhoncus, orci et iaculis sodales, quam lectus suscipit augue, ut auctor massa dolor id metus. Nulla porta ut diam sodales dignissim. Ut sit amet augue vel justo laoreet dignissim. Maecenas vitae sollicitudin eros. In commodo placerat cursus. Quisque malesuada, nisl ac lacinia commodo, justo eros maximus ex, quis cursus odio erat at neque. Sed tincidunt eu dolor eget posuere.<br></br>Curabitur sit amet elit sit amet ligula eleifend aliquam quis eget quam. Mauris id mi nec justo venenatis tincidunt at ac massa. Sed et volutpat nunc. Quisque at urna quam. Duis sit amet neque eget quam iaculis iaculis. Quisque maximus porta elementum. Nam ac mattis erat, quis accumsan odio.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="fun-facts">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="fact-item">
                        <div class="counter" data-count="926">450+</div>
                        <span>Completed Deals</span>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="fact-item">
                        <div class="counter" data-count="214">50+</div>
                        <span>Finished Projects</span>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="fact-item">
                        <div class="counter" data-count="118">400+</div>
                        <span>Happy Clients</span>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="fact-item">
                        <div class="counter" data-count="16">60</div>
                        <span>Years in Presence</span>
                    </div>
                </div>
            </div>
        </div>
    </div> 

    <div id="contact" class="page-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-heading">
                        <h4>Contact Us</h4>
                        <div class="line-dec"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="map">
                        <img src={map} alt=""/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" style={{color:"white"}}>
                    Hemant Dosi - 9425052493<br></br>
Khushang Dosi- 7389210363<br></br>
Address- 204, Anmol Sagar Darshan, new palasia, Indore<br></br>

                      {/* <form id="contact" action="" method="post">
                        <div class="col-md-6">
                          <fieldset>
                            <input name="name" type="text" class="form-control" id="name" placeholder="Your name..." required=""/>
                          </fieldset>
                        </div>
                        <div class="col-md-6">
                          <fieldset>
                            <input name="email" type="email" class="form-control" id="email" placeholder="Your email..." required=""/>
                          </fieldset>
                        </div>
                        <div class="col-md-12">
                          <fieldset>
                            <textarea name="message" rows="6" class="form-control" id="message" placeholder="Your message..." required=""></textarea>
                          </fieldset>
                        </div>
                        <div class="col-md-12">
                          <fieldset>
                            <button type="submit" id="form-submit" class="btn">Send Message</button>
                          </fieldset>
                        </div>
                      </form> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="copyright-text">
                        <p>Copyright &copy; 2023 babulaldosiandsons </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="social-icons">
                        <ul>
                            <li>
                                <a href="#"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa fa-linkedin"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa fa-rss"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa fa-dribbble"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    </>
  )
}

export default Main