import React from 'react'
import "../css/bootstrap.min.css"
import "../css/bootstrap-theme.min.css"
import "../css/fontAwesome.css"
import "../css/hero-slider.css"
import "../css/tooplate-style.css"
import  logo  from "../img/logo.png";

function Nav({settimedPopup}) {
  return (
    <div>
     <div class="header">
        <div class="container">
            <nav class="navbar navbar-inverse" role="navigation">
                <div class="navbar-header">
                    <button type="button" id="nav-toggle" class="navbar-toggle" data-toggle="collapse" data-target="#main-nav">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a href="#" class="navbar-brand scroll-top">
                        <div class="logo"><img src={logo} width={125} height={80}></img></div>
                    </a>
                </div>
                
                <div id="main-nav" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav">
                        <li><a href="#" class="scroll-top">Home</a></li>
                        <li><a href="#" class="scroll-link" data-id="about">Who we are</a></li>
                        <li><a href="#" class="scroll-link" data-id="portfolio">What we do</a></li>
                        <li><a href="#" class="scroll-link" data-id="blog">Our Projects</a></li>
                        <li><a onClick={()=>settimedPopup(true)} class="scroll-link" data-id="contact">Contact Us</a></li>
                    </ul>
                </div>
                
            </nav>
           
        </div>
        
    </div>
   

    </div>
  )
}

export default Nav