import React from 'react'

function HeroPage() {
  return (
    <div>
          <section class="cd-hero">
        <ul class="cd-hero-slider autoplay" style={{height:"100vh"}}>  
            <li class="selected first-slide">
                <div class="cd-full-width">
                    <div class="tm-slide-content-div slide-caption" style={{marginTop:"460px"}}>
                        {/* <span>Introduction to</span> */}
                        <h2>Babulal Dosi & Sons</h2>
                        <p>Trust , prominence & legacy in real estate from the last 60 years.</p>
                        <div class="white-button">
                            <a href="#" class="scroll-link" data-id="about">Discover More</a>
                        </div>                           
                    </div>                   
                </div> 
            </li>

            <li class="second-slide">
                <div class="cd-full-width">
                    <div class="tm-slide-content-div slide-caption">
                        <span>We Are Perfect Staffs</span>
                        <h2>Our Team Members</h2>
                        <p>Donec dolor ipsum, laoreet nec metus non, tempus elementum massa. Donec non elit rhoncus, vestibulum enim sed, rutrum arcu.</p>
                        <div class="primary-button">
                            <a href="#">Read More</a>
                        </div>                        
                    </div>                     
                </div> 
            </li>

            <li class="third-slide">
                <div class="cd-full-width">
                    <div class="tm-slide-content-div slide-caption">
                        <span>Design is a hobby</span>
                        <h2>Responsive Layout</h2>
                        <p>Integer ut dolor eget magna congue gravida ut at arcu. Vivamus maximus neque quis luctus tempus. Vestibulum consequat.</p>
                        <div class="primary-button">
                            <a href="#">View Details</a>
                        </div>                           
                    </div>                         
                </div> 
            </li>
        </ul> 

        <div class="cd-slider-nav">
            <nav>
                <span class="cd-marker item-1"></span>
                
                <ul>
                    <li class="selected"><a href="#0"></a></li>
                    <li><a href="#0"></a></li>
                    <li><a href="#0"></a></li>                        
                </ul>
            </nav> 
        </div> 
    </section>
    </div>
  )
}

export default HeroPage